/* 600/16 */
/* 801/16 */
/* 1100/16 */
/* 768/16 */
html {
  position: relative;
}
@media (min-width: 1000px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
#overlay {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1011;
  background-color: rgba(0, 0, 0, 0.5);
  /*dim the background*/
}
#overlay.show {
  display: block;
}
#cross-sell-notification {
  display: none;
  background: #fff;
  border: 1px solid #d1d1d1;
  width: 250px;
  padding: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  font-weight: bold;
}
#cross-sell-notification p {
  font-size: .875em;
  margin-top: 0;
}
#cross-sell-notification a {
  margin-bottom: 0;
}
#cross-sell-notification #cross-sell-notification-dot {
  display: block;
  height: 15px;
  width: 15px;
  background: red;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}
#self-search-notification {
  display: none;
  background: #fff;
  border: 1px solid #d1d1d1;
  width: 250px;
  padding: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  font-weight: bold;
}
#self-search-notification p {
  font-size: .875em;
  margin-top: 0;
}
#self-search-notification a {
  margin-bottom: 0;
}
#self-search-notification #notification-dot {
  display: block;
  height: 15px;
  width: 15px;
  background: red;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}
#self-search-notification #close-self-search-notification {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
#self-claim-notification {
  display: none;
  background: #fff;
  border: 1px solid #d1d1d1;
  width: 250px;
  padding: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  font-weight: bold;
}
#self-claim-notification p {
  font-size: .875em;
  margin-top: 0;
  letter-spacing: .5px;
  line-height: 18px;
}
#self-claim-notification p span {
  font-weight: normal;
}
#self-claim-notification a {
  margin-bottom: 0;
}
#self-claim-notification a span {
  display: block;
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
}
#self-claim-notification #notification-dot {
  display: block;
  height: 15px;
  width: 15px;
  background: red;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}
#self-claim-notification #close-notification {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.complete-header {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
.secondary-nav {
  background-color: #3E3E3E;
  padding: 17px 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1100px) {
  .secondary-nav {
    display: none;
  }
}
.secondary-nav .inner-nav {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1em;
}
.secondary-nav .inner-nav .guardian-link {
  background: url('/scion/_images/secondary-nav/nav-icon-guardian-white.svg') 9px 8px no-repeat;
  background-size: 21px;
  padding: 6px 9px 3px 38px;
}
.secondary-nav .inner-nav .guardian-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-guardian-blue.svg') 9px 8px no-repeat;
  background-size: 21px;
  padding: 6px 9px 3px 38px;
}
.secondary-nav .inner-nav .guardian-link.active:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-guardian-white.svg') 9px 8px no-repeat #209dd2;
  background-size: 21px;
  color: #fff;
}
.secondary-nav .inner-nav .reports-link {
  background: url('/scion/_images/secondary-nav/nav-icon-reports-white.svg') 0px 1px no-repeat;
  background-size: 12px;
  padding-left: 19px;
}
.secondary-nav .inner-nav .reports-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-reports-blue.svg') 0px 1px no-repeat;
  background-size: 12px;
  padding-left: 19px;
}
.secondary-nav .inner-nav .dark-web-monitoring-link {
  background: url('/scion/_images/secondary-nav/nav-icon-dark-web-monitoring-white.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .dark-web-monitoring-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-dark-web-monitoring-blue.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .dark-web-monitoring-link.active:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-dark-web-monitoring-white.svg') 9px 5px no-repeat #209dd2;
  background-size: 15px;
  color: #fff;
}
.secondary-nav .inner-nav .sex-offender-watch-link {
  background: url('/scion/_images/secondary-nav/nav-icon-sex-offender-watch-white.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .sex-offender-watch-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-sex-offender-watch-blue.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .sex-offender-watch-link.active:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-sex-offender-watch-white.svg') 9px 5px no-repeat #209dd2;
  background-size: 15px;
  color: #fff;
}
.secondary-nav .inner-nav .report-monitoring-link {
  background: url('/scion/_images/secondary-nav/nav-icon-report-monitoring-white.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .report-monitoring-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-report-monitoring-blue.svg') 9px 5px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 30px;
}
.secondary-nav .inner-nav .report-monitoring-link.active:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-report-monitoring-white.svg') 9px 5px no-repeat #209dd2;
  background-size: 15px;
  color: #fff;
}
.secondary-nav .inner-nav .family-tree-link {
  background: url('/scion/_images/secondary-nav/nav-icon-family-tree-white.svg') 9px 7px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 32px;
}
.secondary-nav .inner-nav .family-tree-link:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-family-tree-blue.svg') 9px 7px no-repeat;
  background-size: 15px;
  padding: 6px 9px 3px 32px;
}
.secondary-nav .inner-nav .family-tree-link.active:hover {
  background: url('/scion/_images/secondary-nav/nav-icon-family-tree-white.svg') 9px 7px no-repeat #209dd2;
  background-size: 15px;
  color: #fff;
}
.secondary-nav .inner-nav a {
  margin: 0 25px 0 0;
  color: #fff;
  border-bottom: 3px solid transparent;
  padding-bottom: 2px;
  font-size: 15px;
  letter-spacing: 0.5px;
}
.secondary-nav .inner-nav a:hover {
  color: #209dd2;
}
.secondary-nav .inner-nav a.active {
  background-color: #209dd2;
  border-radius: 4px;
  color: #fff;
}
#_partials-header {
  position: relative;
  font-size: 14px;
  z-index: 1012;
  background: #fff;
}
#_partials-header #main-nav {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1em;
  height: 75px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#_partials-header #main-nav .logo-container {
  padding: 13px 0;
}
@media (max-width: 330px) {
  #_partials-header #main-nav .logo-container {
    padding: 5px 0 0;
  }
}
#_partials-header #main-nav .logo-container img {
  height: 30.5px;
}
@media (max-width: 330px) {
  #_partials-header #main-nav .logo-container img {
    height: 23px;
  }
}
@media (max-width: 1100px) {
  #_partials-header #main-nav .logo-container .logo-word {
    display: none;
  }
}
#_partials-header #main-nav .logo-container .logo-mark {
  display: none;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav .logo-container .logo-mark {
    display: block;
  }
}
#_partials-header #main-nav .close-search {
  position: fixed;
  right: 14px;
  background: url('/scion/_images/mobile-menu/mobile-menu-close.png') left top no-repeat;
  width: 67px;
  background-size: 14px;
  background-position: 66px 24px;
}
#_partials-header #main-nav .navbar-toggle,
#_partials-header #main-nav .close-search {
  display: none;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav .navbar-toggle,
  #_partials-header #main-nav .close-search {
    display: block;
    height: 100%;
    top: 0;
    line-height: 62px;
    padding-left: 1em;
    right: 1em;
    position: relative;
  }
}
#_partials-header #main-nav .navbar-toggle.hide,
#_partials-header #main-nav .close-search.hide {
  display: none;
}
#_partials-header #main-nav .navbar-toggle span,
#_partials-header #main-nav .close-search span {
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 4px;
}
#_partials-header #main-nav .navbar-toggle span.hide,
#_partials-header #main-nav .close-search span.hide {
  display: none;
}
#_partials-header #main-nav .navbar-toggle span .close,
#_partials-header #main-nav .close-search span .close {
  position: absolute;
  right: 25px;
}
#_partials-header #main-nav .navbar-toggle .icon-bar,
#_partials-header #main-nav .close-search .icon-bar {
  width: 100%;
  height: 3px;
  background: #3e3e3e;
  margin: 3px;
  transition: all 0.2s;
}
#_partials-header #main-nav .navbar-toggle .red-dot-notification,
#_partials-header #main-nav .close-search .red-dot-notification {
  background: #f00000;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  position: initial;
  display: inline-block;
}
#_partials-header #main-nav .navbar-toggle.show .top-bar,
#_partials-header #main-nav .close-search.show .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
#_partials-header #main-nav .navbar-toggle.show .middle-bar,
#_partials-header #main-nav .close-search.show .middle-bar {
  opacity: 0;
}
#_partials-header #main-nav .navbar-toggle.show .bottom-bar,
#_partials-header #main-nav .close-search.show .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav {
    display: none;
  }
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
  }
}
#_partials-header #main-nav nav ul {
  margin: 0;
  padding: 0;
}
#_partials-header #main-nav nav ul li {
  list-style: none;
  float: left;
  padding: 23px 20px 23px 22px;
  font-weight: 600;
  background: url('/scion/_images/caret.svg') no-repeat right 20px;
  background-size: 16px;
  line-height: 14px;
  position: relative;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li {
    width: calc(100% - 50px);
    padding: 15px 25px 0;
    border-top: 1px solid #e1e1e1;
    border-left: 0;
    background: none;
  }
}
#_partials-header #main-nav nav ul li:first-child {
  background: none;
}
#_partials-header #main-nav nav ul li.sex-offender-monitoring {
  background: none;
  padding: 23px 15px 23px 15px;
}
#_partials-header #main-nav nav ul li.monitoring span {
  display: inline-block;
  position: relative;
  top: -2px;
  background-color: #f00000;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 6px;
  margin-left: 5px;
  border-radius: 8px;
}
#_partials-header #main-nav nav ul li.dark-web-monitoring-not-activated {
  background: none;
  padding: 23px 15px;
}
#_partials-header #main-nav nav ul li.sex-offender-monitoring {
  background: none;
  padding: 23px 15px 23px 15px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu {
  padding: 5px 0px 0px 0px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li.logout {
  padding: 8px 10px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu {
  padding: 5px 0px 0px 0px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li {
  border-bottom: 1px solid #e1e1e1;
  padding: 4px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li:last-child {
  border-bottom: 0;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li a {
  color: #3e3e3e;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li a:hover {
  color: #209dd2;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li.logout {
  padding: 7px 10px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li.logout a {
  background-color: #57bf93;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}
#_partials-header #main-nav nav ul li.account ul.account-menu li.logout a:hover {
  background-color: #73d6ac;
}
#_partials-header #main-nav nav ul li.support ul li {
  border-bottom: 1px solid #e1e1e1;
  padding: 4px;
}
#_partials-header #main-nav nav ul li.support ul li:last-child {
  border-bottom: 0;
}
#_partials-header #main-nav nav ul li.support ul li:hover {
  color: #209dd2;
}
#_partials-header #main-nav nav ul li.support ul li a {
  color: #3e3e3e;
}
#_partials-header #main-nav nav ul li.support ul li a:hover {
  color: #209dd2;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul {
  width: 340px;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li {
  width: 140px;
  border-bottom: 1px solid #e1e1e1;
  padding: 4px;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li:last-child {
  border-bottom: 0;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li:hover a {
  color: #717171;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li:hover a:hover {
  color: #209dd2;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li.helpful-articles {
  float: right;
  width: 200px;
  border-left: 1px solid #e1e1e1;
  box-sizing: border-box;
  padding: 5px 0;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li.helpful-articles h4 {
  text-transform: uppercase;
  color: #57bf93;
  margin: 0 0 10px 10px;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li.helpful-articles h4 i {
  display: inline-block;
  background: url('/scion/_images/helpful-articles-icon.svg') no-repeat center center;
  background-size: 100%;
  width: 12px;
  height: 12px;
  margin-right: 3px;
}
#_partials-header #main-nav nav ul li.support.show-helpful-articles ul li.helpful-articles a {
  line-height: 18px;
}
#_partials-header #main-nav nav ul li.phone-number {
  background: none;
}
#_partials-header #main-nav nav ul li.phone-number a {
  color: #b1b1b1;
  font-weight: 600;
  letter-spacing: 0;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li.phone-number {
    padding: 15px 25px;
  }
}
#_partials-header #main-nav nav ul li a {
  text-transform: uppercase;
  color: #3e3e3e;
  letter-spacing: .5px;
  font-size: 15.2px;
}
#_partials-header #main-nav nav ul li a .red-count-notification {
  margin: -3px 3px 0px 0;
}
#_partials-header #main-nav nav ul li a .red-dot-notification {
  background: #f00000;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
}
#_partials-header #main-nav nav ul li ul {
  display: none;
  position: absolute;
  border: 1px solid #e1e1e1;
  margin: 20px 0 0 -15px;
  padding: 5px 0;
  background: #fff;
  min-width: 180px;
  width: auto;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li ul {
    display: block;
    position: relative;
    width: 100%;
    border: 0;
    margin-top: 10px;
    padding: 0;
  }
}
#_partials-header #main-nav nav ul li ul li {
  list-style: none;
  float: none;
  border: none;
  font-weight: 400;
  background: none;
  padding: 0;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li ul li {
    width: calc(100% - 10px);
  }
}
#_partials-header #main-nav nav ul li ul li.border-top a {
  border-top: 1px solid #e1e1e1;
  font-weight: 800;
  padding: 10px 10px 5px;
  width: calc(100% - 20px);
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li ul li.border-top a {
    padding: 10px;
  }
}
#_partials-header #main-nav nav ul li ul li.border-top.reports-list {
  margin-top: 5px;
}
#_partials-header #main-nav nav ul li ul li a {
  text-transform: capitalize;
  height: 100%;
  display: block;
  padding: 5px 10px;
  color: #717171;
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li ul li a {
    padding: 10px;
    width: 100%;
  }
}
@media (max-width: 1100px) {
  #_partials-header #main-nav nav ul li ul li:hover {
    background: #e1e1e1;
  }
}
#_partials-header #main-nav nav ul li ul li:hover a {
  color: #20d2cc;
}
#_partials-header #main-nav nav ul li:hover ul {
  display: block;
}
.android-banner-wrapper {
  position: relative;
}
.android-banner {
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  overflow: hidden;
  border-top: 1px solid #ccc;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  position: fixed;
  display: none;
  z-index: 999;
}
.android-banner-container {
  padding: 1em 1em 0em 1em;
}
.android-banner-container .android-app-icon {
  display: inline-block;
}
.android-banner-container .android-app-icon img {
  width: 58px;
}
.android-banner-container .android-banner-info {
  display: inline-block;
  vertical-align: top;
  line-height: 1.2em;
  font-weight: bold;
  padding: 0 8px;
}
.android-banner-container .android-banner-info .free {
  text-transform: uppercase;
  color: #57bf93;
  font-weight: bold;
}
.android-banner-container .android-banner-info .android-banner-title {
  font-size: 15px;
  line-height: 17px;
  color: #000;
  font-weight: bold;
}
.android-banner-container .android-banner-info .ratings img.stars {
  width: 80px;
  display: inline-block;
}
.android-banner-container .android-banner-info .ratings img.person {
  height: 11px;
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}
.android-banner-container .android-banner-info .ratings span {
  display: inline-block;
  vertical-align: top;
  padding: 0px 5px 0px 9px;
  font-weight: normal;
  color: #95989A;
  font-size: 11px;
}
.android-banner-container .android-banner-info .google-play-button img {
  width: 85px;
}
.android-banner-close {
  position: absolute;
  right: 6px;
  top: 12px;
  display: block;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: subpixel-antialiased;
  border: 0;
  width: 30px;
  height: 30px;
  line-height: 17px;
  color: #000;
  font-weight: lighter;
}
.android-banner-button {
  position: absolute;
  right: 14px;
  bottom: 12px;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  background-color: #57bf93;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 9px 15px;
  box-shadow: 0px 2px 0 0px rgba(0, 0, 0, 0.14);
}
.android-banner-button:hover {
  color: #fff;
  background-color: #46b787;
  box-shadow: 0px 1px 0 0px rgba(0, 0, 0, 0.3);
}
.android-banner-button-text {
  text-align: center;
  display: block;
  padding: 0 5px;
}
